<template>
  <div class="bonuses-wager-progress">
    <div v-if="props.bonusInfo.wagerCasino" class="bonuses-wager-progress__row">
      <span class="bonuses-wager-progress__label">
        {{
          getContent(
            globalComponentsContent,
            defaultLocaleGlobalComponentsContent,
            'bonuses.cashBonus.casinoWagerLabel'
          )
        }}
      </span>

      <template v-if="props.bonusInfo.status === 2">
        <span class="bonuses-wager-progress__amount">
          {{ formatAmount(props.bonusInfo.currency, props.bonusInfo.currentWagerCasinoAmount) }}
        </span>

        <span class="bonuses-wager-progress__divider">
          {{
            getContent(
              globalComponentsContent,
              defaultLocaleGlobalComponentsContent,
              'bonuses.cashBonus.wagerSeparator'
            )
          }}
        </span>
      </template>

      <span class="bonuses-wager-progress__amount">{{ fullCasinoAmount }}</span>
    </div>

    <div v-if="props.bonusInfo.wagerSportsbook" class="bonuses-wager-progress__row">
      <span class="bonuses-wager-progress__label">
        {{
          getContent(
            globalComponentsContent,
            defaultLocaleGlobalComponentsContent,
            'bonuses.cashBonus.sportsbookWagerLabel'
          )
        }}
      </span>

      <template v-if="props.bonusInfo.status === 2">
        <span class="bonuses-wager-progress__amount">
          {{ formatAmount(props.bonusInfo.currency, props.bonusInfo.currentWagerSportsbookAmount) }}
        </span>

        <span class="bonuses-wager-progress__divider">
          {{
            getContent(
              globalComponentsContent,
              defaultLocaleGlobalComponentsContent,
              'bonuses.cashBonus.wagerSeparator'
            )
          }}
        </span>
      </template>

      <span class="bonuses-wager-progress__amount">
        {{ fullSportsbookAmount }}
      </span>
    </div>

    <bonuses-progress v-if="props.bonusInfo.status === 2" :progress="props.bonusInfo.currentWagerPercentage" />
    <div
      v-if="props.bonusInfo.currentWagerPercentage === 100 && props.bonusInfo.openedTransactionsCount > 0"
      class="bonuses-wager-progress__waiting"
    >
      <atomic-icon id="pending" filled />
      {{
        getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.cashBonus.waitResultLabel')
      }}
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { IPlayerBonus } from '~/types';

  const props = defineProps<{
    bonusInfo: IPlayerBonus;
  }>();

  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = useGlobalStore();
  const { getContent } = useProjectMethods();

  const { formatAmount } = useProjectMethods();
  const fullCasinoAmount = computed<string>(() => {
    const fullAmount = props.bonusInfo.currentWagerCasinoAmount + props.bonusInfo.requiredWagerCasinoAmount;
    const roundAmount = Number(fullAmount.toFixed(2));
    return formatAmount(props.bonusInfo.currency, roundAmount);
  });

  const fullSportsbookAmount = computed<string>(() => {
    const fullAmount = props.bonusInfo.currentWagerSportsbookAmount + props.bonusInfo.requiredWagerSportsbookAmount;
    const roundAmount = Number(fullAmount.toFixed(2));
    return formatAmount(props.bonusInfo.currency, roundAmount);
  });
</script>

<style src="~/assets/styles/components/bonuses/wager-progress.scss" lang="scss" />
